import accountServices from '../../../services/account'
import { resolve } from 'core-js/fn/promise'
import { Auth } from 'aws-amplify';
export default {
    data () {
      return {    
        email: ''
      }
    },
    methods: {
      async onSubmitLogin(){
        const isValid = await this.$refs['observer'].validate()
        if (!isValid) {
          this._showToast('Please check your information', {variant: 'warning'})
        } else {
          Auth.forgotPassword(this.email)
              .then(data => {
                //console.log(data)
              this.$router.push({name: 'SuccessPage', params: {
                title: 'Sent successfully!',
                subTitle: 'Kindly check your email for further action',
                icon:'far fa-check-circle'
              }})      
              }
                )
              .catch(err =>
                {
                  // if (err.message == "Cannot read property 'auth' of undefined") {
                  //   this.$router.push({name: 'Recalls'})
                  // }
                  // else  
                  // {
                    if(err.message == "Username/client id combination not found."){
                      this._showToast('User not found', { variant: 'warning' })
                    }
                    else
                    {
                      this._showToast(err.message, { variant: 'warning' })
                    }
                    
                  //}
                  //console.log(err)
                }
                );
          // accountServices.forgotPassword({email: this.email}).then(resp => {
          //   if (!resp.error) {
          //     this.$router.push({name: 'SuccessPage', params: {
          //       title: 'Sent successfully!',
          //       subTitle: 'Kindly check your email for further action',
          //       icon:'far fa-check-circle'
          //     }})
          //   }
          //   else  
          //   {
          //     this._showToast(resp.error.message, { variant: 'warning' })
          //   }
          // })
        }
      },
    }
  }